import { useState } from 'react';

import { Box, Button, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { Module } from '../../rete/types';
import { NewModuleModal } from './NewModuleModal';
import { RemoveModuleModal } from './RemoveModuleModal';
import { slugify_text } from '../../util/util';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

type ModuleListProps = {
    setSelectedModule: (module: string) => void;
};

export const ModuleList = (props: ModuleListProps): JSX.Element => {
    const [openNewModal, setOpenNewModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [modules, setModule] = useLocalStorage<Module[]>('modules', []);
    const { t } = useTranslation();

    const saveModule = (moduleName: string): void => {
        const newModule = { label: moduleName, content: [] };
        setModule([...modules, newModule]);
        props.setSelectedModule(moduleName);
        setOpenNewModal(false);
    };

    const removeModule = (moduleValue: string): void => {
        const cleanModules = modules.filter((module: Module) => slugify_text(module.label) !== slugify_text(moduleValue));
        setModule(cleanModules);
        setOpenRemoveModal(false);
    };

    return (
        <>
            <Box className={'modules'}>
                <div className='contentModelesButtons'>
                    {modules.map((module: Module, index: number) => (
                        <Button
                            key={`module-${index}`}
                            id={module.label}
                            className='moduleTabButton'
                            sx={{'ml': 1}}
                            variant="contained"
                            onClick={()=> props.setSelectedModule(module.label)}
                            size='small'
                        >
                            {module.label == 'Main' ? t(`new_module_modal.${module.label}`) : module.label}
                        </Button>
                    ))}
                </div>
                <IconButton
                    size='small'
                    aria-label="add-module"
                    className='moduleIconButton'
                    onClick={() => setOpenNewModal(true)}
                    sx={{ ml: 1 }}
                >
                    <Add sx={{ fontSize: '1.4rem' }} />
                </IconButton>
                <IconButton
                    size='small'
                    aria-label="remove-module"
                    className='moduleIconButton'
                    onClick={() => setOpenRemoveModal(true)}
                    sx={{ ml: 1 }}
                >
                    <Remove sx={{ fontSize: '1.4rem' }} />
                </IconButton>
            </Box>
            <NewModuleModal open={openNewModal} onClose={() => setOpenNewModal(false)} save={saveModule} />
            <RemoveModuleModal open={openRemoveModal} models={modules} onClose={() => setOpenRemoveModal(false)} remove={removeModule} />
        </>
    );
};