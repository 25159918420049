
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    InputLabel,
    FormControl,
    Typography,
    OutlinedInput,
    Select,
    MenuItem,
    Grid,
    IconButton,
    Button,
    Alert,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@mui/material';
import {
    Add,
    WarningAmber,
    Settings,
    Cancel,
    Save,
    ExpandMore,
    ExpandLess,
} from '@mui/icons-material';
import { DiContainer } from '../../../types';
import { InputData } from '../../../models/Input';
import {
    checkJinja2Syntaxis,
    clearMessage,
    getNextNodeName,
    mainParams,
    nodeNameAlreadyExists,
} from '../../../../util/util';
import { CaseComponent } from '../switch_modal/CaseComponent';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../../components/TextEditor';
import { byMessage } from '../../../components/utils/configToolbarEditor';
import { FlowUtils } from '../../../../services';
import { Middleware } from '../../../../services/flow_utils';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { Case } from '../../../models/Switch';
import { reorder } from '../../../components/utils/reorderOnDragList';
import JinjaCodeMirrorEditor from '../../../components/JinjaEditor';

declare type InputModalControlOptions = {
    modalInfo?: InputData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

export class InputModalControl extends Classic.Control {
    constructor(public options?: InputModalControlOptions) {
        super();
    }
}

export const InputModal = (props: { data: InputModalControl }): JSX.Element => {
    const [inputData, setInputData] = useState<InputData>({
        name: getNextNodeName('Input', props.data.options?.di.editor.getNodes()),
        text: '',
        variable: '',
        validation: '',
        input_type: 'm.text',
        validation_attempts: '',
        middlewares: [],
        inactivity_options: {
            chat_timeout: '',
            warning_message: '',
            time_between_attempts: '',
            attempts: '',
        },
        cases: [
            { id: 'default', o_connection: '', variables: [] },
        ],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [enableInactivityOptions, setEnableInactivityOptions] = useState(false);
    const [middlewares, setMiddlewares] = useState<Middleware[]>([]);
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        const [menuflowDomain] = mainParams();
        const flowUtilsService = new FlowUtils(menuflowDomain);

        const getMiddlewares = async (): Promise<void> => {
            const [json, status] = await flowUtilsService.getMiddlewares();
            if (status === 200) {
                setMiddlewares(json);
            }
        };

        const inactivityOptionsState: boolean = (
            inputData.inactivity_options?.chat_timeout !== '' &&
            inputData.inactivity_options?.chat_timeout !== null &&
            inputData.inactivity_options?.warning_message !== undefined
        );
        setEnableInactivityOptions(inactivityOptionsState);
        getMiddlewares();
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (enableInactivityOptions) {
            setInputData({
                ...inputData,
                cases: [...inputData.cases, { id: 'timeout', o_connection: '', variables: [] }],
            });
        } else {
            const updatedCases = inputData.cases.filter((item) => item.id !== 'timeout');
            setInputData({
                ...inputData,
                cases: updatedCases,
            });
        }
    }, [enableInactivityOptions]);

    useMemo(() => {
        if (props.data.options?.modalInfo) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            setInputData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId!);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        inputData.cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id);
            }
        });

        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        inputData.cases.forEach((item) => {
            if (!outputIDs.includes(item.id) && !checkAddConnection(item.o_connection)) {
                node.addOutput(item.id, new Classic.Output(socket, item.id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId!);
    };

    const validateForm = (): string | undefined => {
        if (!inputData.text) {
            return t('input.text_field_can_not_be_empty');
        } else if (inputData.name.length > 20) {
            return t('input.name_field_too_long');
        } else if (inputData.cases.length === 0) {
            return t('input.you_must_add_at_least_one_case');
        } else if (nodeNameAlreadyExists(inputData.name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('input.name_already_exists');
        }
        return;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const errorMessage = validateForm();
        if (errorMessage) {
            showErrorMessage(errorMessage);
            return;
        }

        props.data.options!.modalInfo = {
            name: inputData.name,
            text: clearMessage(inputData.text),
            variable: inputData.variable,
            validation: inputData.validation,
            input_type: inputData.input_type,
            validation_attempts: inputData.validation_attempts,
            middlewares: inputData.middlewares,
            cases: inputData.cases,
        };
        if (enableInactivityOptions) {
            const options = inputData.inactivity_options;
            if (!options?.chat_timeout || !options?.warning_message || !options?.time_between_attempts || !options?.attempts) {
                showErrorMessage(t('input.you_must_fill_all_inactivity_options_fields'));
                return;
            }
            props.data.options!.modalInfo.inactivity_options = inputData.inactivity_options;
        } else {
            props.data.options!.modalInfo.inactivity_options = undefined;
        }

        props.data.options!.di.updateControl(props.data.id);
        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    const addNewCase = (): void => {
        setInputData({
            ...inputData,
            cases: [{ id: '', o_connection: '', variables: [] }, ...inputData.cases],
        });
    };

    const removeCase = (index: number): void => {
        const newCases = [...inputData.cases];
        newCases.splice(index, 1);
        setInputData({ ...inputData, cases: newCases });
    };

    const saveCase = (index: number, key: 'id' | 'o_connection', value: string): void => {
        const newCases = [...inputData.cases];
        newCases[index][key] = value;
        setInputData({ ...inputData, cases: newCases });
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...inputData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setInputData({ ...inputData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...inputData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setInputData({ ...inputData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...inputData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setInputData({ ...inputData, cases: newCases });
    };

    const updateNodeHeigth = (): void => {
        const nodeHeigth = 180 + (inputData.cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId);
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const cases:
            Case[] = reorder(
                inputData.cases,
                result.source.index,
                result.destination.index
            );

        setInputData({
            ...inputData,
            cases: [...cases],
        });
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('input.input_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('input.required_fields')}
                        </Typography>

                        <TextField
                            label={t('input.name')}
                            id='node-name'
                            value={inputData.name}
                            required
                            onChange={(e): void => setInputData({ ...inputData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <TextEditor
                            value={clearMessage(inputData.text)}
                            toolbarOptions={byMessage}
                            activeMarkdown={true}
                            onChange={(value: string): void => setInputData({ ...inputData, text: value })}
                        />
                        <TextField
                            label={t('input.variable_name')}
                            id='variable-name'
                            value={inputData.variable}
                            required
                            onChange={(e): void => setInputData({ ...inputData, variable: e.target.value })}
                            sx={{ m: 1, width: '44ch' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, width: '44ch' }}>
                            <InputLabel id='input-type-label'>{t('input.input_type')}</InputLabel>
                            <Select
                                labelId='input-type-label'
                                id='input-type'
                                value={inputData.input_type}
                                required
                                onChange={(e): void => setInputData({ ...inputData, input_type: e.target.value })}
                                label='Input Type'
                                size="small"
                            >
                                <MenuItem value={'m.text'}>m.text</MenuItem>
                                <MenuItem value={'m.image'}>m.image</MenuItem>
                                <MenuItem value={'m.video'}>m.video</MenuItem>
                                <MenuItem value={'m.audio'}>m.audio</MenuItem>
                                <MenuItem value={'m.file'}>m.file</MenuItem>
                                <MenuItem value={'m.location'}>m.location</MenuItem>
                            </Select>
                        </FormControl>
                        <JinjaCodeMirrorEditor
                            label={'input.validation'}
                            pr={'5%'}
                            jinjaExpression={inputData.validation}
                            onChangeFunction={(value: string): void => setInputData({ ...inputData, validation: value })}
                        />
                        <TextField
                            label={t('input.validation_attempts')}
                            id="validation-attempts"
                            type='number'
                            value={inputData.validation_attempts}
                            onChange={(e): void => setInputData({ ...inputData, validation_attempts: e.target.value })}
                            sx={{ m: 1, width: '44ch' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, width: '44ch' }}>
                            <InputLabel size="small" id='middlewares-label'>{t('input.middlewares')}</InputLabel>
                            <Select
                                labelId='middlewares-label'
                                id='middlewares'
                                multiple
                                size="small"
                                value={inputData.middlewares}
                                input={<OutlinedInput size="small" label='Tag' />}
                                onChange={(e): void => setInputData({ ...inputData, middlewares: e.target.value as string[] })}
                                renderValue={(selected): string => selected.join(', ')}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {middlewares.map((middleware: { id: string, type: string }, index: number) => {
                                    if (['jwt', 'base', 'basic'].includes(middleware.type)) {
                                        return;
                                    }
                                    return (
                                        <MenuItem key={`${middleware.id}-${index}`} value={middleware.id}>
                                            {middleware.id}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <Grid container spacing={2} className='mt-16 mb-16'>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('input.inactivity_options')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='float-right'>
                                    <Switch
                                        size="small"
                                        checked={enableInactivityOptions}
                                        color='primary'
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={(): void => setEnableInactivityOptions(!enableInactivityOptions)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <TextField
                            label={t('input.chat_timeout')}
                            id="chat-timeout"
                            type='number'
                            value={inputData.inactivity_options?.chat_timeout}
                            onChange={(e): void => setInputData({ ...inputData, inactivity_options: { ...inputData.inactivity_options, chat_timeout: e.target.value } })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            size="small"
                        />
                        <TextField
                            label={t('input.warning_message')}
                            id="warning-message"
                            value={inputData.inactivity_options?.warning_message}
                            onChange={(e): void => setInputData({ ...inputData, inactivity_options: { ...inputData.inactivity_options, warning_message: e.target.value } })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            multiline
                            size="small"
                        />
                        <TextField
                            label={t('input.time_between_attempts')}
                            id="time-between-attempts"
                            type='number'
                            value={inputData.inactivity_options?.time_between_attempts}
                            onChange={(e): void => setInputData({
                                ...inputData,
                                inactivity_options: {
                                    ...inputData.inactivity_options, time_between_attempts: e.target.value,
                                },
                            })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            size="small"
                        />
                        <TextField
                            label={t('input.attempts')}
                            id="attempts"
                            type='number'
                            value={inputData.inactivity_options?.attempts}
                            onChange={(e): void => setInputData({
                                ...inputData,
                                inactivity_options: {
                                    ...inputData.inactivity_options, attempts: e.target.value,
                                },
                            })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            size="small"
                        />
                        <Grid container spacing={0} className='cases-container' sx={{ pl: 3, pr: 3 }}>
                            <Grid container spacing={0} sx={{ mb: 1 }}>
                                <Grid item xs={10}>
                                    <Typography
                                        textAlign="left"
                                        variant='h6'
                                        sx={{ ml: 1, fontSize: '1.2rem' }}
                                    >
                                        {t('input.cases')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box className='round-button' textAlign={'end'}>
                                        <Tooltip title={t('case_component.tooltip_add_case')}>
                                            <IconButton
                                                aria-label="round-button"
                                                onClick={addNewCase}
                                                sx={{ ml: 5 }}
                                            >
                                                <Add sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} justifySelf={'start'}>
                                    <Box className='expand-case-option' textAlign={'start'}>
                                        <IconButton
                                            aria-label='expand-case-item'
                                            onClick={() => { handleExpandClick(); }}
                                            sx={{ ml: 1 }}
                                        >
                                            {expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                            {!expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppableInputModalCases">
                                    {(provided: DroppableProvided) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {inputData.cases.map((item, index) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ ml: 1, pr: 4 }}
                                                    key={`components-input-index-cases-${index}`}

                                                >
                                                    <CaseComponent
                                                        key={index}
                                                        data={item}
                                                        index={index}
                                                        removeCase={removeCase}
                                                        saveCase={saveCase}
                                                        addVariable={addVariable}
                                                        removeVariable={removeVariable}
                                                        saveVariable={saveVariable}
                                                        expanded={expanded}
                                                        textFileWidth={'47%'}
                                                    />
                                                </Grid>
                                            ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Grid>

                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('input.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('input.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};