import {
    Grid,
} from '@mui/material';
import {
    InteractiveAction,
    InteractiveData,
    InteractiveItems,
    InteractiveOption,
} from '../../models/Interactive';
import PreviewButtons from './PreviewButtons';
import PreviewLists from './PreviewLists';

const PreviewMessage = (
    props: {
        interactiveData: InteractiveData
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]
        interactiveTypeOption: string
        bridgeOption: string
        interactiveTypeMedia: string
    }
) => {

    return (
        <Grid
            container
            spacing={1}
            className='container-buttons-and-list-preview'
        >
            <Grid item xs={12} sx={{ alignContent: 'initial', justifyContent: 'start' }}>
                <Grid
                    container
                    spacing={1}
                    sx={{ pl: 2 }}
                >
                    {/*
                        Check if the  interactiveTypeOption is a button to show the button options,
                        otherwise show the list options
                    */}
                    {props.interactiveTypeOption === 'button' && (
                        <PreviewButtons
                            interactiveData={props.interactiveData}
                            interactiveTypeMedia={props.interactiveTypeMedia}
                            interactiveOptionsValues={props.interactiveOptionsValues}
                        />
                    )}
                    {props.interactiveTypeOption === 'list' && (
                        <PreviewLists
                            interactiveData={props.interactiveData}
                            interactiveTypeMedia={props.interactiveTypeMedia}
                            interactiveOptionsValues={props.interactiveOptionsValues}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PreviewMessage;