
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Button,
    Alert,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListSubheader,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Tooltip,
    Grid,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save, Help } from '@mui/icons-material';
import { DiContainer } from '../../../types';
import { DistributeChatData } from '../../../models/DistributeChat';
import { AcdService } from '../../../../services';
import { mainParams, getNextNodeName, nodeNameAlreadyExists } from '../../../../util/util';
import { useTranslation } from 'react-i18next';
import { StaticCaseVariables } from '../../../components/StaticCaseVariables';

declare type DistributeChatModalControlOptions = {
    modalInfo?: DistributeChatData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

type DestinationSelect = {
    label: string;
    value: string;
};

export class DistributeChatModalControl extends Classic.Control {
    constructor(public options?: DistributeChatModalControlOptions) {
        super();
    }
}

export const DistributeChatModal = (props: { data: DistributeChatModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [distributeChatData, setDistributeChatData] = useState<DistributeChatData>({
        name: getNextNodeName('Distribute Chat', props.data.options?.di.editor.getNodes()),
        destination: '',
        joined_message: '',
        put_enqueued_portal: 'yes',
        force_distribution: 'no',
        queue_timeout: 0,
        campaign: '',
        subcampaign: '',
        cases: [
            { 'id': '202', 'o_connection': '', 'variables': [] },
            { 'id': 'default', 'o_connection': '', 'variables': [] },
        ],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [agents, setAgents] = useState<DestinationSelect[]>([]);
    const [queues, setQueues] = useState<DestinationSelect[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const [menuflowDomain, botMxid] = mainParams();
        const acdService = new AcdService(menuflowDomain, botMxid);

        const fetchDestinationData = async (): Promise<void> => {
            const [queues, queueStatus] = await acdService.getQueues();
            const [agents, AgentStatus] = await acdService.getUsers('AGENT');

            if (queueStatus !== 200 || AgentStatus !== 200) {
                showErrorMessage(t('distribute_chat.failed_to_fetch_destination_data'));
            }

            const queuesMap = queues.queues.map((queue) => {
                return {
                    label: queue.name,
                    value: queue.room_id,
                };
            });
            setQueues(queuesMap);

            const agentsMap = agents.users.map((agent) => {
                return {
                    label: agent.displayname,
                    value: agent.mxid,
                };
            });
            setAgents(agentsMap);
        };

        fetchDestinationData();
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options!.modalInfo));
            setDistributeChatData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (!distributeChatData.destination) {
            return t('distribute_chat.destination_field_can_not_be_empty');
        }
        if (distributeChatData.name.length > 20) {
            return t('distribute_chat.name_field_too_long');
        } else if (nodeNameAlreadyExists(distributeChatData.name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('distribute_chat.name_already_exists');
        }
        return undefined;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = {
            name: distributeChatData.name,
            destination: distributeChatData.destination,
            joined_message: distributeChatData.joined_message,
            put_enqueued_portal: distributeChatData.put_enqueued_portal,
            force_distribution: distributeChatData.force_distribution,
            queue_timeout: distributeChatData.queue_timeout,
            campaign: distributeChatData.campaign,
            subcampaign: distributeChatData.subcampaign,
            cases: distributeChatData.cases,
        };
        props.data.options?.di.updateNode(props.data.options?.nodeId);
        onClose();
    };

    const renderDestinationOptions = (): JSX.Element[] => {
        const destinationOptions: JSX.Element[] = [];

        destinationOptions.push(<ListSubheader key="queues-header">{t('distribute_chat.queues')}</ListSubheader>);
        queues.map((destination) => (
            destinationOptions.push(
                <MenuItem key={destination.value} value={destination.value}>
                    {destination.label}
                </MenuItem>
            )
        ));

        destinationOptions.push(<ListSubheader key="agents-header">{t('distribute_chat.agents')}</ListSubheader>);
        agents.map((destination) => (
            destinationOptions.push(
                <MenuItem key={destination.value} value={destination.value}>
                    {destination.label !== null ? destination.label : destination.value}
                </MenuItem>
            )
        ));

        return destinationOptions;
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...distributeChatData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setDistributeChatData({ ...distributeChatData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...distributeChatData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setDistributeChatData({ ...distributeChatData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...distributeChatData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setDistributeChatData({ ...distributeChatData, cases: newCases });
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('distribute_chat.distribute_chat_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TextField
                            label={t('distribute_chat.name')}
                            id='node-name'
                            value={distributeChatData.name ?? ''}
                            required
                            onChange={(e): void => setDistributeChatData({ ...distributeChatData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, width: '97%' }} size="small">
                            <InputLabel id='input-type-label'>{t('distribute_chat.destination')}</InputLabel>
                            <Select
                                labelId='destination-label'
                                id='destination'
                                value={distributeChatData.destination}
                                required
                                onChange={(e): void => setDistributeChatData({ ...distributeChatData, destination: e.target.value })}
                                label={t('distribute_chat.destination')}
                                autoFocus
                            >
                                {renderDestinationOptions()}
                            </Select>
                        </FormControl>
                        <TextField
                            label={t('distribute_chat.joined_agent_message')}
                            id='variable-name'
                            value={distributeChatData.joined_message ?? ''}
                            onChange={(e): void => setDistributeChatData({ ...distributeChatData, joined_message: e.target.value })}
                            multiline
                            sx={{ m: 1, width: '97%' }}
                            size="small"
                            fullWidth
                        />
                        <TextField
                            label={t('distribute_chat.campaign')}
                            id='queue-timeout'
                            value={distributeChatData.campaign ?? ''}
                            onChange={(e): void => setDistributeChatData({ ...distributeChatData, campaign: e.target.value })}
                            sx={{ m: 1, width: '47%' }}
                            size="small"
                            fullWidth
                        />
                        <TextField
                            label={t('distribute_chat.subcampaign')}
                            id='queue-timeout'
                            value={distributeChatData.subcampaign ?? ''}
                            onChange={(e): void => setDistributeChatData({ ...distributeChatData, subcampaign: e.target.value })}
                            sx={{ m: 1, width: '47%' }}
                            size="small"
                            fullWidth
                        />
                        <Divider sx={{ m: 1, background: '#1976d247' }} />
                        <Typography sx={{ ml: 1, mt: 2 }} variant="caption" display="block" gutterBottom>
                            {t('distribute_chat.additional_options')}
                        </Typography>
                        <Grid container spacing={0}>
                            <Grid container item xs={6}>
                                <Grid item xs={11}>
                                    <FormControl sx={{ m: 1, display: 'flex' }} size='small'>
                                        <InputLabel id='put-enqueue-label'>
                                            {t('distribute_chat.enqueue_chat')}
                                        </InputLabel>
                                        <Select
                                            labelId='put-enqueue-label'
                                            id='put-enqueue'
                                            value={distributeChatData.put_enqueued_portal}
                                            onChange={(e): void => setDistributeChatData({ ...distributeChatData, put_enqueued_portal: e.target.value })}
                                            label={t('distribute_chat.enqueue_chat') + '\s\s\s;'}
                                        >
                                            <MenuItem value={'yes'}>{t('distribute_chat.yes')}</MenuItem>
                                            <MenuItem value={'no'}>{t('distribute_chat.no')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                    <div style={{width: 'min-content'}}>
                                        <Tooltip title={t('distribute_chat.tooltip_enqueue_chat')} arrow>
                                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6}>
                                <Grid item xs={11}>
                                    <FormControl sx={{ m: 1, display: 'flex' }} size='small'>
                                        <InputLabel id='force-distribute-label'>
                                            {t('distribute_chat.force_distribution')}
                                        </InputLabel>
                                        <Select
                                            labelId='force-distribute-label'
                                            id='force-distribute'
                                            value={distributeChatData.force_distribution}
                                            onChange={(e): void => setDistributeChatData({ ...distributeChatData, force_distribution: e.target.value })}
                                            label={t('distribute_chat.force_distribution') + '\s\s\s'}
                                            size="small"
                                        >
                                            <MenuItem value={'yes'}>{t('distribute_chat.yes')}</MenuItem>
                                            <MenuItem value={'no'}>{t('distribute_chat.no')}</MenuItem>
                                            <MenuItem value={'logged'}>{t('distribute_chat.logged')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                    <div style={{width: 'min-content'}}>
                                        <Tooltip title={t('distribute_chat.tooltip_force_distribution')} arrow>
                                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', mr: 1, cursor: 'pointer' }} />
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} sx={{mt:1}}>
                            <Grid item xs={11}>
                                <TextField
                                    label={t('distribute_chat.queue_timeout')}
                                    placeholder=''
                                    id='queue-timeout'
                                    value={distributeChatData.queue_timeout ?? ''}
                                    onChange={(e): void => setDistributeChatData({ ...distributeChatData, queue_timeout: parseInt(e.target.value) })}
                                    sx={{ width: '101.8%', ml: 1 }}
                                    size="small"
                                    fullWidth
                                    type='number'
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                <div style={{width: 'min-content', float: 'inline-end', marginRight: '3px' }}>
                                    <Tooltip title={t('distribute_chat.tooltip_queue_timeout')} arrow>
                                        <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', cursor: 'pointer' }} />
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                        <Typography textAlign='left' variant='subtitle1' sx={{ mb: 2 }}>{t('distribute_chat.cases')}</Typography>
                        {distributeChatData.cases.map((item, index) => (
                            <StaticCaseVariables
                                key={index}
                                index={index}
                                data={item}
                                saveVariable={saveVariable}
                                removeVariable={removeVariable}
                                addVariable={addVariable}
                            />
                        ))}
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('distribute_chat.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('distribute_chat.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};