import { useEffect, useState } from 'react';
import { useRete } from 'rete-react-plugin';
import './App.css';
import './rete.css';
import { createEditor as editor } from './rete/editor';
import { ExportButton, FlowVariables } from './components';
import { Alert, Box, Typography} from '@mui/material';
import { ModuleList } from './components/modules/ModuleList';
import { Module, NodeData } from './rete/types';
import useLocalStorage from './hooks/useLocalStorage';
import { FlowService } from './services';
import { mainParams, clearEditor } from './util/util';
import { loadFlow } from './rete/import_flow';
import { export_flow } from './rete/export_flow';

const [menuflowDomain, botMxid] = mainParams();
const flowService = new FlowService(menuflowDomain, botMxid);

function App(): JSX.Element {
    const [ref, editorProps] = useRete(editor);
    const [showAlert, setshowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [prevModule, setPrevModule] = useState('');
    const [modules, setModules ] = useLocalStorage<Module[]>('modules', []);
    const [selectedModule, setSelectedModule] = useLocalStorage('selected_module', 'Main');

    const showMainAlert = (message: string): void => {
        setAlertMessage(message);
        setshowAlert(true);
        setTimeout(() => setshowAlert(false), 3000);
    };

    function generateModules(nodes: NodeData[]): Module[] {
        const grouped = nodes.reduce((result: Module[], item: NodeData) => {
            const keyValue = item['module'] || 'Main';

            let group = result.find(g => g.label === keyValue);
            if (!group) {
                group = { label: keyValue, content: [] };
                result.push(group);
            }

            group.content.push(item);
            return result;
        }, [] as Module[]);

        return grouped.length > 0 ? grouped : [{'label': 'Main', 'content':[]}];
    };

    const getModules = async(): Promise<Module[]> => {
        const [flow, status] = await flowService.getFlow();
        if (status !== 200) {
            console.error('Error loading flow');
            return [];
        }

        const nodes = flow.flow.menu.nodes;
        return generateModules(nodes);
    };

    const openModule = async(module: Module): Promise<void> => {
        await clearEditor(editorProps!.di.editor);
        await loadFlow(editorProps!.di, module.content);
        editorProps!.arranger();
    };

    const updateSelectedModule = (module: string): void => {
        setPrevModule(selectedModule);
        setSelectedModule(module);
    };

    const saveModule = (moduleName: string): void => {
        const exportedNodes = export_flow(moduleName, editorProps!.di.editor);
        const modulesCopy = modules.slice();
        const moduleIndex = modulesCopy.findIndex((module: Module) => module.label === moduleName);
        modulesCopy[moduleIndex] = {label: moduleName, content: exportedNodes};
        setModules([...modulesCopy]);
    };

    useEffect(() => {
        const retriveModules = async() => {
            const modulesRetrieved = await getModules();
            setModules(modulesRetrieved);
            setSelectedModule('Main');
            const mainModule = modulesRetrieved.find((module: Module) => module.label === 'Main');
            openModule(mainModule!);
        };

        if (editorProps) retriveModules();
    }, [editorProps]);

    useEffect(() => {
        if (modules.length > 1 && editorProps) {
            saveModule(prevModule);
            const module = modules.find((module: Module) => module.label === selectedModule);
            openModule(module!);
        }
        if (selectedModule && editorProps){

            const element = document.getElementById(selectedModule);
            const prevElement = document.getElementById(prevModule);
            if (prevElement instanceof HTMLElement) {
                prevElement.classList.remove('selectedModuleTabButton');
            }
            if (element instanceof HTMLElement) {
                element.classList.add('selectedModuleTabButton');
            }
        }
        else{
            if(selectedModule == 'Main'){
                const element = document.getElementById(selectedModule);
                if (element instanceof HTMLElement) {
                    element.classList.add('selectedModuleTabButton');
                }
            }
        }
    }, [selectedModule]);

    return (
        <div className='editor'>
            <div className='container-editor'>
                {showAlert &&
                    <Alert severity='success' className='mt-16 mb-16 ml-16 main-alert'>
                        <Typography textAlign={'center'} variant='body1' gutterBottom>
                            {alertMessage}
                        </Typography>
                    </Alert>
                }
                <ModuleList setSelectedModule={updateSelectedModule} />
                <Box className={'export-button'}>
                    <ExportButton
                        editor={editorProps?.di.editor}
                        saveModule={() => saveModule(selectedModule)}
                        showMainAlert={showMainAlert}
                    />
                </Box>
                <Box className={'flow-variables-button'}>
                    <FlowVariables showMainAlert={showMainAlert} />
                </Box>
                <div ref={ref} className="rete"></div>
            </div>
        </div>
    );
}

export default App;
