import { useEffect, useState } from 'react';
import { InteractiveAction, InteractiveData, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { marked } from 'marked';

const PreviewButtons = (
    props: {
        interactiveData: InteractiveData
        interactiveTypeMedia: string
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]
    }
) => {
    const [gridSize, setGridSize] = useState(props.interactiveOptionsValues.length < 3 ? 6 : 12);
    const [htmlContent, setHtmlContent] = useState<string>('');

    useEffect(() => {
        let message = '';
        if (props.interactiveData && props.interactiveData.interactive_message?.content?.text) {
            message = props.interactiveData.interactive_message.content.text;
        }
        processMessage(message);
    }, [props.interactiveData]);

    useEffect(() => {
        if (!props.interactiveOptionsValues.length) return;

        if (props.interactiveOptionsValues.length < 3) {
            setGridSize(6);
        } else {
            setGridSize(12);
        }
    }, [props.interactiveOptionsValues]);

    const processMessage = async (_message: string) => {
        let messageInProcess = _message;
        // Change line breaks with <br />
        messageInProcess = messageInProcess.replace(/\n/g, '<br />');

        // Convert the message to HTML using marked
        const htmlMessage = await marked(messageInProcess);

        setHtmlContent(`<div class="msgContent">${htmlMessage}</div>`);
    };

    return (
        <>
            <Grid
                item
                xs={10}
                sx={{
                    mt: 1,
                    pb: 1,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
                className='preview-container'
            >
                {
                    // Check if the interactiveTypeMedia is image and the url is not empty to display the image
                    props.interactiveTypeMedia === 'image' &&
                    props.interactiveData.interactive_message.content?.url && (
                        <img
                            src={props.interactiveData.interactive_message.content?.url}
                            style={{
                                width: '98%',
                                height: 'auto',
                                borderRadius: '8px',
                            }}
                        />
                    )
                }
                {
                    // Check if the interactiveTypeMedia is video and the url is not empty to display the video
                    props.interactiveTypeMedia === 'video' &&
                    props.interactiveData.interactive_message.content?.url && (
                        <video
                            src={props.interactiveData.interactive_message.content?.url}
                            style={{
                                width: '98%',
                                height: 'auto',
                                borderRadius: '8px',
                            }}
                            controls
                        />
                    )
                }
                {
                    // Check if the interactiveTypeMedia is document and the url is not empty to display the document
                    props.interactiveTypeMedia === 'document' &&
                    props.interactiveData.interactive_message.content?.url && (
                        <Box
                            className='document-preview'
                        >
                            <img
                                src={'/pdf-image.webp'}
                                style={{
                                    height: '100%',
                                    borderRadius: '8px',
                                }}
                            />
                        </Box>
                    )
                }
                {/*Check if the interactive_message has a header to display*/}
                {props.interactiveData.interactive_message.content?.header && (
                    <Typography
                        textAlign='left'
                        variant='h6'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: '#444',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {props.interactiveData.interactive_message.content?.header}
                    </Typography>
                )}
                {/*Check if the interactive_message has a text to display*/}
                {props.interactiveData.interactive_message.content?.text && (
                    // Display the text using dangerouslySetInnerHTML to render the text as HTML
                    <div
                        className='message-content'
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                )}
                {/*Check if the interactive_message has a caption to display*/}
                {props.interactiveData.interactive_message.content?.caption && ((
                    <Typography
                        textAlign='left'
                        variant='body2'
                        sx={{
                            fontSize: '0.85rem',
                            color: '#777',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {props.interactiveData.interactive_message.content?.caption}
                    </Typography>
                ))}
            </Grid>
            <Grid
                item
                xs={10}
                className='button-container'
            >
                <Grid
                    container
                    spacing={1}
                    sx={{ mb: 2 }}
                >
                    {
                        // Check if the interactiveOptionsValues is not empty to display the buttons
                        props.interactiveOptionsValues &&
                        props.interactiveOptionsValues.length > 0 && (
                            props.interactiveOptionsValues.map((option, index) => {
                                // Check if the option has a title to display it,
                                // otherwise return an empty div
                                if ((option as InteractiveOption).title) {
                                    return (
                                        <Grid
                                            item
                                            xs={gridSize}
                                            key={`button-${index}`}
                                        >
                                            <Box
                                                className='button-preview'
                                            >
                                                {(option as InteractiveOption).title}
                                            </Box>
                                        </Grid>
                                    );
                                }
                                else return <div key={`button-${index}`}></div>;
                            })
                        )
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default PreviewButtons;