import React, { forwardRef } from 'react';

interface CustomEmojiButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CustomEmojiButton = forwardRef<HTMLButtonElement, CustomEmojiButtonProps>(({ onClick }, ref) => (
    <button ref={ref} onClick={onClick}>
        😊
    </button>
));

export default CustomEmojiButton;