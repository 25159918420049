import { AccessTime, Add, CalendarMonth, DateRange, Remove, Today } from '@mui/icons-material';
import { Grid, IconButton, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { CheckTimeData, week_days, months, days_of_month, Range } from '../../../models/CheckTime';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

declare type RangeSectionProps = {
    title: string;
    data: Range[];
    key_name: keyof CheckTimeData;
    add: (key: keyof CheckTimeData) => void;
    remove: (index: number, key: keyof CheckTimeData) => void;
    saveStart: (index: number, key: keyof CheckTimeData, value: string) => void;
    saveEnd: (index: number, key: keyof CheckTimeData, value: string) => void;
};

export const RangeSection = (props: RangeSectionProps): JSX.Element => {
    const { t } = useTranslation();

    const get_select_data = (): Array<{ value: string, label: string }> | undefined => {
        switch (props.key_name) {
        case 'days_of_week':
            return week_days;
        case 'days_of_month':
            return days_of_month;
        case 'months':
            return months;
        }
    };

    const getIconByKeyName = (key_name: string) => {
        switch (key_name) {
        case 'time_ranges':
            return <AccessTime />;
        case 'days_of_week':
            return <DateRange />;
        case 'days_of_month':
            return <CalendarMonth  />;
        case 'months':
            return <Today />;
        }
    };

    const renderDateRanges = (): JSX.Element[] => {
        const select_data = get_select_data();
        return props.data.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center'  }}>
                <FormControl sx={{ m: 1, width: '20ch' }}>
                    <InputLabel size="small" id='input-type-label'>{t('start')}</InputLabel>
                    <Select
                        labelId='start-label'
                        id='start'
                        value={item.start}
                        required
                        onChange={(e): void => { props.saveStart(index, props.key_name, e.target.value); }}
                        label='Start'
                        MenuProps={MenuProps}
                        size="small"
                    >
                        {select_data!.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{t(option.label)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: '20ch' }}>
                    <InputLabel size="small" id='input-type-label'>{t('end')}</InputLabel>
                    <Select
                        labelId='end-label'
                        id='end'
                        value={item.end}
                        onChange={(e): void => { props.saveEnd(index, props.key_name, e.target.value); }}
                        label='End'
                        MenuProps={MenuProps}
                        disabled={props.data[index].start === '*' ? true : false}
                        size="small"
                    >
                        {select_data!.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{t(option.label)}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className={'box-button-style'} >
                    <IconButton
                        aria-label='remove-range'
                        sx={{ m: 1 }}
                        size="small"
                        onClick={(): void => props.remove(index, props.key_name)}>
                        <Remove sx={{ fontSize: '1rem'  }}/>
                    </IconButton>
                </Box>
            </Box>
        ));
    };

    const parseTime = (dateStr: string): string => {
        const date = new Date(dateStr);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return`${hours}:${minutes}`;
    };

    const renderTimeRanges = (): JSX.Element[] => {
        return props.data.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center'  }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label={t('start')}
                        value={item.start ? dayjs(`0000-00-00T${item.start}`, {utc: false}) : null}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                        onAccept={(value) => {
                            if (!value) return;
                            props.saveStart(index, props.key_name, parseTime(value.toISOString()));
                        }}
                        sx={{ m: 1 }}
                    />
                    <TimePicker
                        label={t('end')}
                        value={item.end ? dayjs(`0000-00-00T${item.end}`, { utc: false}) : null}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                        onAccept={(value) => {
                            if (!value) return;
                            props.saveEnd(index, props.key_name, parseTime(value.toISOString()));
                        }}
                    />
                </LocalizationProvider>
                <Box className={'box-button-style'} >
                    <IconButton
                        aria-label='remove-range'
                        sx={{ m: 1 }}
                        size="small"
                        onClick={(): void => props.remove(index, props.key_name)}>
                        <Remove sx={{ fontSize: '1rem'  }}/>
                    </IconButton>
                </Box>
            </Box>
        ));
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <div className='time_range_content'>
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <div className='time_range_title'>
                        <div className='icon_title_time'>{getIconByKeyName(props.key_name)}</div>
                        <div>{props.title} *</div>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <div className='float-right box-button-style'>
                        <IconButton
                            size="small"
                            aria-label='add-time-range'
                            onClick={(): void => props.add(props.key_name)}
                            sx={{ mt: 1 }}
                        >
                            <Add sx={{ fontSize: '1rem'  }}/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <div>
                {props.key_name === 'time_ranges' && renderTimeRanges()}
                {props.key_name !== 'time_ranges' && renderDateRanges()}
            </div>
        </div>
    );
};