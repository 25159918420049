
import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Button,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save } from '@mui/icons-material';
import { DiContainer } from '../../../types';
import { StartSubData } from '../../../models/StartSub';
import { useTranslation } from 'react-i18next';
import { getNextNodeName, nodeNameAlreadyExists } from '../../../../util/util';

declare type StartSubModalControlOptions = {
    modalInfo?: StartSubData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

export class StartSubModalControl extends Classic.Control {
    constructor(public options?: StartSubModalControlOptions) {
        super();
    }
}

export const StartSubModal = (props: { data: StartSubModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [startSubData, setStartSubData] = useState<StartSubData>({
        name: getNextNodeName('StartSub', props.data.options!.di.editor.getNodes()),
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) {
            setStartSubData(props.data.options.modalInfo);
        }
    }, [open, startSubData]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (startSubData.name.length > 20) {
            return t('start_sub.name_field_too_long');
        } else if (nodeNameAlreadyExists(startSubData.name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('start_sub.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = startSubData;
        props.data.options!.di.updateNode(props.data.options!.nodeId);
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('start_sub.start_sub_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('start_sub.required_fields')}
                        </Typography>
                        <TextField
                            label={t('start_sub.name')}
                            id='node-name'
                            value={startSubData.name}
                            required
                            onChange={(e): void => setStartSubData({ ...startSubData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('start_sub.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('start_sub.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};