import { ClassicScheme, RenderEmit, Presets } from 'rete-react-plugin';
import styled, { css } from 'styled-components';
import { $nodeheight, $nodewidth, $socketmargin, $socketsize } from '../../components/utils/vars';
import {
    AccessTime,
    AppRegistration,
    Bookmark,
    Email,
    Http,
    Input,
    ListAlt,
    LocationOn,
    Mms,
    MoreTime,
    PersonAdd,
    PersonRemove,
    Schema,
    Textsms,
    SmartToy,
    SmartButton,
    Outbound,
    MoveUp,
    RadioButtonChecked,
    BackupTable,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { checkJinja2Syntaxis } from '../../../util/util';
import {
    MessageModalControl,
    InputModalControl,
    CheckTimeModalControl,
    DistributeChatModalControl,
    EmailModalControl,
    GptAssistantModalControl,
    HttpRequestModalControl,
    InviteUserModalControl,
    MediaModalControl,
    SetVarsModalControl,
    SwitchModalControl,
    SubroutineModalControl,
    InteractiveModalControl,
    GotoModalControl,
} from '../../controls';

const { RefSocket, RefControl } = Presets.classic;

type NodeExtraData = { label: string, width?: number; height?: number };

type NodeColors = {
    background: string;
    selected_hover: string;
}
const getNodeColor = (nodeLabel: string): NodeColors => {
    switch (nodeLabel) {
    case 'Subroutine':
    case 'StartSub':
        return {background: 'rgb(0 84 110 / 63%) !important', selected_hover: 'rgb(0 84 110 / 75%) !important'};
    case 'Goto':
    case 'StartSection':
        return {background: 'rgb(67 135 90 / 63%) !important', selected_hover: 'rgb(67 135 90 / 75%) !important'};
    default:
        return {background: 'rgb(0 0 0 / 25%) !important', selected_hover: 'rgb(0 0 0 / 45%) !important'};
    };
};

export const NodeStyles = styled.div<
    NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  background: ${(props) => getNodeColor(props.label).background};
  border: 1px solid #495057;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
        Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  height: ${(props) =>
        Number.isFinite(props.height) ? `${props.height}px` : `${$nodeheight}px`};
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  &:hover {
    background: ${(props) => getNodeColor(props.label).selected_hover};
  }
  ${(props) =>
        props.selected &&
        css`
      border-color: black !important;
      background: ${getNodeColor(props.label).selected_hover};
    `}
  .title {
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    padding: 8px;
    display: flex;
    align-items: center;
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    text-align: right;
    margin-right: -16px;
    display: inline-block;
  }
  .input-socket {
    text-align: left;
    margin-left: -16px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    position: absolute;
    bottom: 0px;
    left: -11px;
    padding: 6px 12px;
    display: contents;
  }
  .label-name{
    margin-left: 10px;
  }
  ${(props) => props.styles && props.styles(props)}
`;

function sortByIndex<T extends [string, undefined | { index?: number }][]>(
    entries: T
) {
    entries.sort((a, b) => {
        const ai = a[1]?.index || 0;
        const bi = b[1]?.index || 0;

        return ai - bi;
    });
}

type Props<S extends ClassicScheme> = {
    data: S['Node'] & NodeExtraData;
    styles?: () => any;
    emit: RenderEmit<S>;
};
export type NodeComponent<Scheme extends ClassicScheme> = (
    props: Props<Scheme>
) => JSX.Element;

export function CustomNode<Scheme extends ClassicScheme>(props: Props<Scheme>) {
    const { t } = useTranslation();
    const inputs = Object.entries(props.data.inputs);
    const outputs = Object.entries(props.data.outputs);
    const controls = Object.entries(props.data.controls);
    const selected = props.data.selected || false;
    const { id, label, width, height } = props.data;

    sortByIndex(inputs);
    sortByIndex(outputs);
    sortByIndex(controls);

    const selectedIconNode = (labelNode: string) => {
        const defaultNodeName = t(`nodes.${labelNode}`);
        let nodeName = '';
        switch (labelNode) {
        case 'Message':
            nodeName = (props.data.controls?.modal as MessageModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Textsms fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Input':
            nodeName = (props.data.controls?.modal as InputModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Input fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Switch':
            nodeName = (props.data.controls?.modal as SwitchModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <ListAlt fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Media':
            nodeName = (props.data.controls?.modal as MediaModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Mms fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Set Vars':
            nodeName = (props.data.controls?.modal as SetVarsModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <AppRegistration fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Subroutine':
            nodeName = (props.data.controls?.modal as SubroutineModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Outbound fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Check Time':
            nodeName = (props.data.controls?.modal as CheckTimeModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <AccessTime fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Invite User':
            nodeName = (props.data.controls?.modal as InviteUserModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <PersonAdd fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Leave':
            nodeName = (props.data.controls?.modal as InviteUserModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <PersonRemove fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Location':
            nodeName = (props.data.controls?.modal as InviteUserModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <LocationOn fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Delay':
            nodeName = (props.data.controls?.modal as InviteUserModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <MoreTime fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Distribute Chat':
            nodeName = (props.data.controls?.modal as DistributeChatModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Schema fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'HttpRequest':
            nodeName = (props.data.controls?.modal as HttpRequestModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Http fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Email':
            nodeName = (props.data.controls?.modal as EmailModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <Email fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'GptAssistant':
            nodeName = (props.data.controls?.modal as GptAssistantModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <SmartToy fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Interactive':
            nodeName = (props.data.controls?.modal as InteractiveModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <SmartButton fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'StartSub':
            nodeName = (props.data.controls?.modal as SubroutineModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <RadioButtonChecked fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'Goto':
            nodeName = (props.data.controls?.modal as GotoModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <MoveUp fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        case 'StartSection':
            nodeName = (props.data.controls?.modal as SubroutineModalControl).options?.modalInfo?.name ?? defaultNodeName;
            return (
                <>
                    <BackupTable fontSize='medium' />
                    <div className='label-name'>{nodeName}</div>
                </>
            );
        default:
            return (
                <>
                    <Bookmark fontSize='medium' />
                    <div className='label-name'>{t(`nodes.${labelNode}`)}</div>
                </>
            );
        }
    };

    return (
        <NodeStyles
            label={props.data.label}
            selected={selected}
            width={width}
            height={height}
            styles={props.styles}
            data-testid='node'
        >
            <div
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
                className='title'
                data-testid='title'
            >
                {selectedIconNode(label)}
            </div>
            {/* Outputs */}
            {outputs.map(
                ([key, output]) =>
                    output && (
                        <div className='output' key={key} data-testid={`output-${key}`}>
                            <div className='output-title' data-testid='output-title'>
                                {checkJinja2Syntaxis(output?.label ?? '')? t('conditional') : t(output?.label ?? '')}
                            </div>
                            <RefSocket
                                name='output-socket'
                                side='output'
                                emit={props.emit}
                                socketKey={key}
                                nodeId={id}
                                payload={output.socket}
                            />
                        </div>
                    )
            )}
            {/* Controls */}
            {controls.map(([key, control]) => {
                return control ? (
                    <RefControl
                        key={key}
                        name='control'
                        emit={props.emit}
                        payload={control}
                    />
                ) : null;
            })}
            {/* Inputs */}
            {inputs.map(
                ([key, input]) =>
                    input && (
                        <div className='input' key={key} data-testid={`input-${key}`}>
                            <RefSocket
                                name='input-socket'
                                emit={props.emit}
                                side='input'
                                socketKey={key}
                                nodeId={id}
                                payload={input.socket}
                            />
                            {input && (!input.control || !input.showControl) && (
                                <div className='input-title' data-testid='input-title'>
                                    {t(`nodes.${input?.label}`)}
                                </div>
                            )}
                            {input?.control && input?.showControl && (
                                <span className='input-control'>
                                    <RefControl
                                        key={key}
                                        name='input-control'
                                        emit={props.emit}
                                        payload={input.control}
                                    />
                                </span>
                            )}
                        </div>
                    )
            )}
        </NodeStyles>
    );
}