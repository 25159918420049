import React, { useState } from 'react';
import {
    Draggable,
    DraggableProvided,
} from 'react-beautiful-dnd';
import {
    Typography,
    IconButton,
    TextField,
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
} from '@mui/material';
import { InteractiveAction, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import { Dehaze, Help, Remove } from '@mui/icons-material';
import { t } from 'i18next';
import EmojiPickerComponent, {alignButton} from '../EmojiPcker';
import { getFormatTextAddEmoji } from '../../../util/util';

const InteractiveListOptions = (
    props: {
        index_item: number,
        index: number,
        item: InteractiveOption | InteractiveItems | InteractiveAction,
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[],
        setInteractiveOptionsValues: (interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]) => void,
        expandedOptions: boolean,
        setExpandedOptions: (expandedOptions: boolean) => void,
        handleExpandOptionClick: () => void,
    }
) => {
    const [messageInput, setMessageInput] = useState<HTMLInputElement | null>(null);

    const removeInteractiveListOption = (index_item: number, index_option: number): void => {
        if ((props.interactiveOptionsValues[index_item] as InteractiveItems).options.length > 1) {
            const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
            (newInteractiveOptionsValues[index_item] as InteractiveItems).options.splice(index_option, 1);
            props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveItems[]);
        }
    };

    const handleChangeOption = (text: string) => {
        const newInteractiveListValues = [...props.interactiveOptionsValues];
        (newInteractiveListValues[props.index_item] as InteractiveItems).options[props.index].description = text;
        props.setInteractiveOptionsValues(newInteractiveListValues as InteractiveItems[]);
    };

    const addEmoji = (emoji: string): void => {
        //add emoji to the message
        const templateInput = messageInput?.id;
        const text = document.getElementById(templateInput || '') as HTMLTextAreaElement;
        // value of the message
        const textValue = text.value;
        // cursor position
        const cursorPosition = text.selectionStart;
        text.value = getFormatTextAddEmoji(textValue, cursorPosition, emoji);
        handleChangeOption(text.value);
    };

    const getMessageInput = (input: HTMLInputElement | null) => {
        if (input) {
            setMessageInput(input);
        }
    };

    return (
        <Draggable
            draggableId={`list-option-${props.index}`}
            index={props.index}
        >
            {(provided: DraggableProvided) => (
                <Box
                    className='section-list-option-container'
                    sx={{ mb: 4 }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Accordion
                        defaultExpanded
                        expanded={props.expandedOptions}
                        sx={{
                            background: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            m: 0,
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel3-content"
                        >
                            {/*Option container */}
                            <Grid container spacing={0}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        alignSelf: 'center',
                                        textAlign: 'center',
                                        height: 0,
                                    }}
                                >
                                    <Tooltip title={t('interactive.tooltip_move_option')}>
                                        <IconButton
                                            aria-label='move-interactive-option'
                                            size='small'
                                            sx={{
                                                ml: 1,
                                                bottom: 30,
                                                width: '47px',
                                                background: 'white',
                                                borderRadius: '6px',
                                                border: '1px dashed',
                                            }}
                                            {...provided.dragHandleProps}
                                        >
                                            <Dehaze sx={{ fontSize: '1rem' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        textAlign='left'
                                        variant='subtitle1'
                                        sx={{ fontWeight: 900, ml: 1, display: 'inline' }}
                                    >
                                        {`${t('interactive.option')} ${props.index + 1}`}
                                    </Typography>
                                    {!props.expandedOptions && (
                                        <Typography
                                            textAlign='left'
                                            variant='subtitle1'
                                            sx={{ color: '#555', ml: 1, display: 'inline' }}
                                        >
                                            - {(props.item as InteractiveOption).title}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container >
                                <Grid container item xs={6} spacing={0}>
                                    <Grid item xs={11}>
                                        {/*
                                            postback_text or case identificator, it is use to identify the value of the response
                                            when the client select an option of the list
                                        */}
                                        <TextField
                                            label={t('interactive.postback_text')}
                                            id={`interactive-postback_text-${props.index}`}
                                            value={(props.item as InteractiveOption).postback_text}
                                            onChange={(e): void => {
                                                const newInteractiveListValues = [...props.interactiveOptionsValues];
                                                (newInteractiveListValues[props.index_item] as InteractiveItems).options[props.index].postback_text = e.target.value;
                                                props.setInteractiveOptionsValues(newInteractiveListValues as InteractiveItems[]);
                                            }}
                                            required
                                            sx={{ ml: 1, width: '95%' }}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                        <Tooltip title={t('interactive.tooltip_postback_text')}>
                                            <Help color="primary" fontSize='small' sx={{ verticalAlign: 'middle', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                {/* Title of the section option */}
                                <Grid item xs={4}>
                                    <TextField
                                        label={t('interactive.title')}
                                        id={`interactive-title-list-option-${props.index}`}
                                        value={(props.item as InteractiveOption).title}
                                        onChange={(e): void => {
                                            const newInteractiveListValues = [...props.interactiveOptionsValues];
                                            (newInteractiveListValues[props.index_item] as InteractiveItems).options[props.index].title = e.target.value;
                                            props.setInteractiveOptionsValues(newInteractiveListValues as InteractiveItems[]);
                                        }}
                                        required
                                        sx={{ ml: 1, width: '120%' }}
                                        size="small"
                                        inputProps={{ maxLength: 24 }}
                                    />
                                </Grid>
                                {/*Button that delete a section option */}
                                <Grid item xs={2} sx={{ alignSelf: 'center' }}>
                                    <Box className='remove-interactive-option' sx={{ ml: 2, mt: .5 }}>
                                        <Tooltip title={t('interactive.tooltip_remove_option')}>
                                            <IconButton
                                                aria-label='remove-interactive-option'
                                                sx={{ ml: 4, width: '100%' }}
                                                onClick={(): void => { removeInteractiveListOption(props.index_item, props.index); }}
                                            >
                                                <Remove sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                {/*Description of the section option */}
                                <Grid item xs={12} sx={{display:'flex', alignItems: 'end'}}>
                                    <TextField
                                        label={t('interactive.description')}
                                        id={`interactive-description-${props.index}`}
                                        value={(props.item as InteractiveOption).description}
                                        onChange={(e): void => { handleChangeOption(e.target.value); }}
                                        sx={{ width: '90%', ml: 1, mt: 1.5, mr: 0 }}
                                        size="small"
                                        inputProps={{ maxLength: 72 }}
                                        inputRef={getMessageInput}
                                        autoComplete="off"
                                    />
                                    <EmojiPickerComponent onEmojiSelect={(e:string) => addEmoji(e)} align={alignButton.bottom}/>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
        </Draggable>
    );
};

export default InteractiveListOptions;