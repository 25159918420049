
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Button,
    Alert,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, Save, Add, Remove } from '@mui/icons-material';
import { DiContainer } from '../../../types';
import { EmailData } from '../../../models/Email';
import { useTranslation } from 'react-i18next';
import { byEmail } from '../../../components/utils/configToolbarEditor';
import TextEditor from '../../../components/TextEditor';
import { mainParams, getNextNodeName, checkValidUrl, nodeNameAlreadyExists } from '../../../../util/util';
import { FlowUtils } from '../../../../services';

declare type EmailModalControlOptions = {
    modalInfo?: EmailData;
    nodeId: string;
    di: DiContainer;
    socket: Classic.Socket;
};

export class EmailModalControl extends Classic.Control {
    constructor(public options?: EmailModalControlOptions) {
        super();
    }
}

export const EmailModal = (props: { data: EmailModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [EmailData, setEmailData] = useState<EmailData>({
        name: getNextNodeName('Email', props.data.options?.di.editor.getNodes()),
        type: '',
        server_id: '',
        subject: '',
        recipients: [''],
        attachments: [''],
        text: '',
        format: 'html',
        encode_type: 'utf-8',
    });
    const [emailServers, setEmailServers] = useState<string[]>([]);
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const [menuflowDomain] = mainParams();
        const flowUtilsService = new FlowUtils(menuflowDomain);

        const getEmailServers = async (): Promise<void> => {
            const [json, status] = await flowUtilsService.getEmailServers();
            if (status === 200) {
                setEmailServers(json.email_servers);
            }
        };

        getEmailServers();
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            setEmailData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkEmail = (email: string): boolean => {
        const re = new RegExp('(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$)');
        return re.test(email);
    };

    const validateForm = (): string | undefined => {
        if (EmailData.name.length > 20) {
            return t('email.name_field_too_long');
        } else if (!EmailData.text) {
            return t('email.text_field_can_not_be_empty');
        } else if (EmailData.recipients.length === 0) {
            return t('email.you_must_add_at_least_one_recipient');
        } else if (EmailData.recipients.some((recipient) => !checkEmail(recipient))) {
            return t('email.invalid_recipient_email');
        } else if (EmailData.attachments && EmailData.attachments?.some((attachment) => attachment && !checkValidUrl(attachment))) {
            return t('email.invalid_attachment_url');
        } else if (nodeNameAlreadyExists(EmailData.name, props.data.options?.nodeId, props.data.options?.di.editor.getNodes())) {
            return t('email.name_already_exists');
        }

    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const errorMessage = validateForm();
        if (errorMessage) {
            showErrorMessage(errorMessage);
            return;
        }

        props.data.options!.modalInfo = EmailData;
        props.data.options?.di.updateNode(props.data.options?.nodeId);
        onClose();
    };

    const addRecipient = (): void => {
        const newEmailData = { ...EmailData };
        newEmailData.recipients.push('');
        setEmailData(newEmailData);
    };

    const removeRecipient = (index: number): void => {
        const newEmailData = { ...EmailData };
        newEmailData.recipients.splice(index, 1);
        setEmailData(newEmailData);
    };

    const addAttachment = (): void => {
        const newEmailData = { ...EmailData };
        newEmailData.attachments?.push('');
        setEmailData(newEmailData);
    };

    const removeAttachment = (index: number): void => {
        const newEmailData = { ...EmailData };
        newEmailData.attachments?.splice(index, 1);
        setEmailData(newEmailData);
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title"  textAlign={'center'} variant='h6' gutterBottom>
                    {t('email.email_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TextField
                            label={t('email.name')}
                            id='node-name'
                            value={EmailData.name}
                            required
                            onChange={(e): void => setEmailData({ ...EmailData, name: e.target.value })}
                            sx={{ m: 1, width: '48%' }}
                            size="small"
                        />
                        <TextField
                            autoFocus
                            label={t('email.server_id')}
                            id='email-server-id'
                            value={EmailData.server_id}
                            onChange={(e): void => setEmailData({ ...EmailData, server_id: e.target.value })}
                            sx={{ m: 1, width: '48%' }}
                            required
                            multiline
                            size="small"
                            select
                        >
                            {emailServers.map((server: string, index: number) => (
                                <MenuItem key={`${server}-${index}`} value={server}>
                                    {server}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            label={t('email.subject')}
                            id='email-subject'
                            value={EmailData.subject}
                            onChange={(e): void => setEmailData({ ...EmailData, subject: e.target.value })}
                            sx={{ m: 1, width: '93.5ch' }}
                            required
                            multiline
                            size="small"
                        />
                        <Grid container className='mt-16'>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('email.recipients')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='float-right box-button-style'>
                                    <IconButton aria-label='add-recipient' onClick={addRecipient}><Add sx={{ fontSize: '1rem' }} /></IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        {EmailData.recipients.map((item, index) => (
                            <Box key={index} className='recipient_content' sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    label={t('email.variable_or_email')}
                                    id={`add-recipient-id-${index}`}
                                    value={item}
                                    onChange={(e): void => {
                                        const newEmailData = { ...EmailData };
                                        newEmailData.recipients[index] = e.target.value;
                                        setEmailData(newEmailData);
                                    }}
                                    required
                                    sx={{ m: 1, width: '82.6ch' }}
                                    size="small"
                                />
                                <Box className={'box-button-style'}>
                                    <IconButton
                                        aria-label='remove-recipient-email'
                                        sx={{ m: 1 }}
                                        onClick={(): void => removeRecipient(index)}
                                    >
                                        <Remove sx={{ fontSize: '1rem' }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                        <TextEditor
                            value={EmailData.text}
                            toolbarOptions={byEmail}
                            activeMarkdown={false}
                            onChange={(value: string): void => setEmailData({ ...EmailData, text: value })}
                        />
                        <Grid container className='mt-16'>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('email.attachments')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='float-right box-button-style'>
                                    <IconButton aria-label='add-attachment' onClick={addAttachment}><Add sx={{ fontSize: '1rem' }} /></IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        {EmailData.attachments?.map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    label={t('email.variable_or_url')}
                                    id={`add-attachment-id-${index}`}
                                    value={item}
                                    onChange={(e): void => {
                                        const newEmailData = { ...EmailData };
                                        if (newEmailData.attachments !== undefined)
                                            newEmailData.attachments[index] = e.target.value;
                                        setEmailData(newEmailData);
                                    }}
                                    sx={{ m: 1, width: '82.6ch' }}
                                    size="small"
                                />
                                <Box className={'box-button-style'}>
                                    <IconButton aria-label='remove-attachment-email' sx={{ m: 1 }} onClick={(): void => removeAttachment(index)}>
                                        <Remove sx={{ fontSize: '1rem'  }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('email.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<Save />}>{t('email.save_changes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};