import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { jinja2 } from '@codemirror/legacy-modes/mode/jinja2';
import { StreamLanguage } from '@codemirror/language';
import { githubLight } from '@uiw/codemirror-theme-github';
import { Grid } from '@mui/material';
import { t } from 'i18next';

interface JinjaCodeMirrorEditorProps {
    label: string;
    pr?: string;
    jinjaExpression: string;
    onChangeFunction: (value: string) => void;
}

const JinjaCodeMirrorEditor: React.FC<JinjaCodeMirrorEditorProps> = ({
    label,
    pr,
    jinjaExpression,
    onChangeFunction,
}): JSX.Element => {

    return (
        <Grid container mt={1.5} mb={2} spacing={1} ml={0} pr={pr} >
            <Grid item xs={12} ml={1} p={0} className='jinjaContainer'>
                <CodeMirror
                    placeholder={t(label)}
                    value={jinjaExpression}
                    theme={githubLight}
                    onChange={onChangeFunction}
                    extensions={[StreamLanguage.define(jinja2)]}
                    className='jinjaEditor'
                />
            </Grid>
        </Grid>
    );
};

export default JinjaCodeMirrorEditor;
