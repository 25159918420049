import React, { useState, useCallback, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { convertToRaw, EditorState, Modifier, ContentState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EmojiPicker from 'emoji-picker-react';
import htmlToDraft from 'html-to-draftjs';
import config from '../../config.json';
import { mdToDraftjs, draftjsToMd } from 'draftjs-md-converter';
import CustomEmojiButton from '../components/CustomEmojiButton';

interface TextEditorProps {
    value: string;
    toolbarOptions: Object;
    activeMarkdown: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
}

const TextEditor = ({ value, toolbarOptions, activeMarkdown, onChange, disabled }: TextEditorProps) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
    const emojiPickerButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const markdown = value || '';
        const rawData = activeMarkdown ? mdToDraftjs(markdown) : htmlToDraft(markdown); // Simplificación de la asignación

        if (rawData) {
            const contentState = 'contentBlocks' in rawData
                ? ContentState.createFromBlockArray(rawData.contentBlocks)
                : convertFromRaw(rawData); // Uso de operador ternario para simplificar
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, []);

    const handleEditorStateChange = useCallback((newEditorState: EditorState) => {
        setEditorState(newEditorState);
        let message = '';
        const contentState = newEditorState.getCurrentContent();
        if (activeMarkdown) {
            message = draftjsToMd(convertToRaw(contentState));
        } else {
            message = draftToHtml(convertToRaw(contentState));
        }
        onChange(message);
    }, [activeMarkdown, onChange]);

    const addEmoji = (emoji: string) => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            emoji,
            editorState.getCurrentInlineStyle(),
        );
        const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
        setEditorState(newEditorState);
        handleEditorStateChange(newEditorState);
        setEmojiPickerVisible(false);
    };

    const toggleEmojiPicker = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setEmojiPickerVisible(prev => !prev);
    };

    const handleEditorClick = (e: React.MouseEvent) => {
        if (e.ctrlKey) { // Verificar si la tecla Ctrl está presionada
            const selection = window.getSelection();
            if (selection && selection.type === 'Range') {
                selection.removeAllRanges();
                const range = document.caretRangeFromPoint(e.clientX, e.clientY);
                if (range) {
                    selection.addRange(range);
                }
            }
        }
    };

    return (
        <div onClick={handleEditorClick}>
            <Editor
                editorState={editorState}
                wrapperClassName="text-editor-rich-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={handleEditorStateChange}
                toolbarCustomButtons={[<CustomEmojiButton ref={emojiPickerButtonRef} onClick={toggleEmojiPicker}/>]}
                toolbar={toolbarOptions}
                localization={{ locale: config.language }}
                readOnly={disabled ?? false}
            />
            {isEmojiPickerVisible && createPortal(
                <div
                    style={{
                        position: 'absolute',
                        top: emojiPickerButtonRef.current ? `${emojiPickerButtonRef.current.getBoundingClientRect().bottom + window.scrollY}px` : '0',
                        left: emojiPickerButtonRef.current ? `${emojiPickerButtonRef.current.getBoundingClientRect().left + window.scrollX}px` : '0',
                        zIndex: 9999,
                    }}
                >
                    <EmojiPicker
                        lazyLoadEmojis
                        onEmojiClick={(emojiObject: { emoji: string }) => addEmoji(emojiObject.emoji)}
                    />
                </div>,
                document.body
            )}
        </div>
    );
};

export default TextEditor;