import { Box, Button, Grid, Typography } from '@mui/material';
import {
    InteractiveAction,
    InteractiveData,
    InteractiveItems,
    InteractiveOption,
} from '../../models/Interactive';
import { useEffect, useState } from 'react';
import PreviewListOptions from './PreviewListOptions';
import { marked } from 'marked';

const PreviewLists = (
    props: {
        interactiveData: InteractiveData
        interactiveTypeMedia: string
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]
    }
) => {
    const [showDiv, setShowDiv] = useState(false);
    const [htmlContent, setHtmlContent] = useState<string>('');

    useEffect(() => {
        let message = '';
        if (props.interactiveData && props.interactiveData.interactive_message.body) {
            message = props.interactiveData.interactive_message.body as string;
        }
        processMessage(message);
    }, [props.interactiveData]);

    const processMessage = async (_message: string) => {
        let messageInProcess = _message;
        // Change line breaks with <br />
        messageInProcess = messageInProcess.replace(/\n/g, '<br />');

        // Convert the message to HTML using marked
        const htmlMessage = await marked(messageInProcess);

        setHtmlContent(`<div class="msgContent">${htmlMessage}</div>`);
    };

    const toggleDiv = () => {
        setShowDiv(!showDiv);
    };

    return (
        <>
            <Grid
                item
                xs={10}
                sx={{
                    mt: 1,
                    pb: 1,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
                className='preview-container'
            >
                {/*Check if the list message has a title */}
                {props.interactiveData.interactive_message.title && (
                    <Typography
                        textAlign='left'
                        variant='h6'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            color: '#444',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {props.interactiveData.interactive_message.title}
                    </Typography>
                )}
                {/*Check if the list message has a body, then render the body as HTML*/}
                {props.interactiveData.interactive_message.body && (
                    <div
                        className='message-content'
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                )}
            </Grid>
            {/*Global button container*/}
            <Grid item xs={10} className='list-container'>
                <Grid
                    container
                    spacing={1}
                    sx={{ mb: 2 }}
                >
                    {
                        props.interactiveData.interactive_message.global_buttons &&
                        props.interactiveData.interactive_message.global_buttons.map((button, index) => {
                            // Check if the global button has a title to display it, otherwise return an empty div
                            if (!button.title) return <div key={`global-button-${index}`}></div>;
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={`global-button-${index}`}
                                >
                                    <Button
                                        className='button-preview'
                                        variant='contained'
                                        color='primary'
                                        onClick={toggleDiv}
                                        fullWidth
                                    >
                                        {button.title as string}
                                    </Button>
                                </Grid>
                            );
                        })
                    }

                </Grid>
            </Grid>
            {/*List options container*/}
            <Grid
                item
                xs={12}
                sx={{ pr: 2 }}
                className='container-list-preview'
            >
                {/*Container that showed the list options if the global button is clicked*/}
                <Grid
                    container
                    spacing={1}
                    className={`slide-div ${showDiv ? 'visible' : ''}`}
                    sx={{
                        height: 'min-content',
                        pl: 1,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            height: '100%',
                        }}
                        className='slide-div-list-container'
                    >
                        {
                            props.interactiveData.interactive_message.global_buttons &&
                            props.interactiveData.interactive_message.global_buttons.map((button, index) => {
                                // Check if the global button has a title to display the title of it and add
                                // a close button in the showed container, otherwise return an empty div
                                if (!button.title) return <div key={`text-global-button-${index}`}></div>;
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        key={`text-global-button-${index}`}
                                        sx={{ mb: 1.5 }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'initial',
                                            }}
                                        >
                                            <span className='close' onClick={toggleDiv}>
                                                &times;
                                            </span>
                                            <Typography
                                                textAlign='center'
                                                variant='h6'
                                                sx={{
                                                    fontSize: '1rem',
                                                    color: '#444',
                                                    width: '86%',
                                                    overflowWrap: 'break-word',
                                                }}
                                            >
                                                {button.title as string}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                );
                            })
                        }
                        {/*List options values*/}
                        <PreviewListOptions
                            interactiveData={props.interactiveData}
                            interactiveOptionsValues={props.interactiveOptionsValues}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PreviewLists;